import { useRouter } from 'next/router';
import MetaHead from '@/components/util/MetaHead';
import MaintenanceLayout from '@/components/layouts/MaintenanceLayout/MaintenanceLayout';

const Home = (): JSX.Element => {
  // Temporary client side redirect as failsafe
  // TODO: Remove this entire page from web application
  // Check if the URL is www or root, if not, redirect to the branded login
  const router = useRouter();
  if (typeof window !== 'undefined') {
    if (
      window.location.hostname.startsWith('www.') ||
      window.location.hostname.startsWith('gettactic.com') ||
      window.location.search.includes('logout=true')
    ) {
      router.push(`${process.env.NEXT_PUBLIC_TACTIC_DOMAIN_AND_PORT}`);
    } else {
      location.href = '/auth/login?homepage=true';
    }
  }

  return (
    <>
      <MetaHead
        title="Where office and remote work meet"
        description="Tactic helps proactive companies return to the office and make long-term decisions backed by data. Whether your office is hybrid, flexible, or merely has logistical challenges, we're here to help."
        pageURL="https://gettactic.com/"
        canonicalURL="https://www.gettactic.com"
      />
      <MaintenanceLayout>
        <h1 className="p-2 text-base">Redirecting...</h1>
      </MaintenanceLayout>
    </>
  );
};

export default Home;
