import React from 'react';

export const MaintenanceLayout = ({
  children
}: React.PropsWithChildren): JSX.Element => (
  <>
    <div>{children}</div>
  </>
);

export default MaintenanceLayout;
